import React, { useState, useEffect } from 'react';
import AdminJHApi from "../../utils/AdminJHApi"
import { Button, Dialog, DialogActions, DialogContent, IconButton, Paper, TextField, Typography } from '@material-ui/core';
import JSData from '../JSData';
import JSTablePagination from '../JSTablePagination';
import SearchIcon from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Fields } from '../Fields';
import { FIELD_TYPES, cleanHiddenChars } from '../../utils/utils';
import MarketingPostsDialog from './MarketingPostsDialog';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import DateRangeIcon from '@material-ui/icons/DateRange';

const useStyles = makeStyles((theme) => ({
    selected: {
        color: 'white !important',
        backgroundColor: theme.palette.primary.main + ' !important'
    },
}))

function Search(props) {
    //const cells = props.cells || []

    //const filteredCells = cells.filter(cell => cell.search)

    const [search, setSearch] = useState('');
    //const [fields, setFields] = useState([]);

    const handleChangeSearch = e => {
        setSearch(e.target.value)
    };

    /*const handleToggleField = (event, newFields) => {
        setFields(newFields||[]);
    };*/

    const handleSearch = e => {
        e.preventDefault();
        //props.handleSearch(search, fields)
        props.handleSearch(search, [])
    }

    return (
        <form onSubmit={handleSearch}>
            <TextField size='small' variant='outlined' placeholder="Search" margin="none" value={search || ''} onChange={handleChangeSearch} type="search"/>
            {/*(filteredCells.length>0)&&<ToggleButtonGroup value={fields} onChange={handleToggleField} size='small' style={{margin: '0 8px'}}>
                {filteredCells.map(cell => (
                    <ToggleButton key={cell.id} value={cell.id} aria-label={cell.label}>
                        {cell.label}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>*/}
            <IconButton disabled={props.disabled/*||(fields.length===0&&search!=='')*/} aria-label="Search" color='primary' style={{padding: 10}} onClick={handleSearch} type="submit">
                <SearchIcon fontSize='small' />
            </IconButton>
        </form>
    )
}

function SearchExtra(props) {
    const extraSearch = props.extraSearch || {}
    const showExtraSearch = props.showExtraSearch || false
    const disabled = props.disabled || false

    const [newData, setNewData] = useState({...extraSearch});

    useEffect(() => {
        if(showExtraSearch){
            setNewData({...extraSearch})
        }
    }, [showExtraSearch, extraSearch]);

    const handleCancel = () => {
        setNewData({...extraSearch})
        props.setShowExtraSearch(false)
    }

    const handleChangeField = e => {
        setNewData({...newData,[e.target.name]: e.target.value})
    };

    const getValue = (name, defaultValue) => {
        if (newData[name] !== undefined){
            return newData[name]
        } else {
            return defaultValue
        }
    }

    const handleSave = e => {
        e.preventDefault();

        props.search(newData)
    }

    const fields = [
        { name: 'from', label:'From', defaultValue: '', type:FIELD_TYPES.DATE2, sm:6},
        { name: 'to', label:'To', defaultValue: '', type:FIELD_TYPES.DATE2, sm:6},
    ]

    return (
        <Dialog onClose={handleCancel} open={showExtraSearch} fullWidth maxWidth="xs">
            <form onSubmit={handleSave}>
                <DialogContent dividers>
                    <Fields fields={fields} getValue={getValue} handleChange={handleChangeField}/>
                </DialogContent>
                <DialogActions>
                    <Button disabled={disabled} onClick={handleCancel} color="primary" size="small" aria-label="Close">
                        Close
                    </Button>
                    {<Button
                        disabled={disabled}
                        type="submit"
                        variant="outlined"
                        color="primary"
                        size="small"
                        aria-label='Search'
                    >
                        Search
                    </Button>}
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default function MarketingPosts(props) {
    const [count, setCount] = useState(0);
    const [data, setData] = useState([]);
    const [cells, setCells] = useState([]);
    const [error, setError] = useState();
    const [disabled, setDisabled] = useState(false);

    const [search, setSearch] = useState('');
    const [showExtraSearch, setShowExtraSearch] = useState(false);
    const [extraSearch, setExtraSearch] = useState({});
    const [fieldsToSearch, setFieldsToSearch] = useState([]);
    const [page, setPage] = useState(0);
    const [types, setTypes] = useState([]);

    const [objectToEdit, setObjectToEdit] = useState();

    useEffect(() => {
        const now = new Date();
        const from = new Date(now.getFullYear()-1, now.getMonth(), now.getDate()).toISOString()
        const to = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 2).toISOString()

        AdminJHApi.getMPosts({extraSearch:{from, to}},(response)=>{
            setTypes(['Review', 'Video', 'Professional'])
            setData(response.data||[])
            setCells(response.cells||[])
            setCount(response.count||0)
            setExtraSearch({from, to})
            setError()
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
        })
    }, []);

    const handleSearch = (searchParam, fieldsToSearch) => {
        const search = cleanHiddenChars(searchParam)

        setSearch(search||'')
        setFieldsToSearch(fieldsToSearch||[])
        setDisabled(true)

        AdminJHApi.getMPosts({search, fieldsToSearch, extraSearch, types},(response)=>{
            setData(response.data||[])
            setCount(response.count||0)
            setPage(0);
            setError()
            setDisabled(false)
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            setDisabled(false)
        })
    }

    const handleExtraSearch = newSearch => {
        setExtraSearch(newSearch||{})
        setShowExtraSearch(false)
        setDisabled(true)

        AdminJHApi.getMPosts({search, fieldsToSearch, extraSearch:newSearch, types},(response)=>{
            setData(response.data||[])
            setCount(response.count||0)
            setPage(0);
            setError()
            setDisabled(false)
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            setDisabled(false)
        })
    }

    const handleTypesChange = (event, newFilterTypes) => {
        setTypes(newFilterTypes)

        setDisabled(true)

        AdminJHApi.getMPosts({search, fieldsToSearch, extraSearch, types: newFilterTypes},(response)=>{
            setData(response.data||[])
            setCount(response.count||0)
            setPage(0);
            setError()
            setDisabled(false)
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            setDisabled(false)
        })
    }

    const handleShowFilter = () => {
        setShowExtraSearch(true)
    };

    const handleChangePage = newPage => {
        setDisabled(true)

        AdminJHApi.getMPosts({search, fieldsToSearch, extraSearch, page:newPage, types},(response)=>{
            setData(response.data||[])
            setCells(response.cells||[])
            setCount(response.count||0)
            setPage(newPage);
            setError()
            setDisabled(false)
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            setDisabled(false)
        })
    };

    const handleNew=()=>{
        setObjectToEdit({
        })
    }

    const handleEditRow=(post, params={})=>{
        setObjectToEdit({...post})
    }

    const handelRefresh = () => {
        setDisabled(true)

        AdminJHApi.getMPosts({search, fieldsToSearch, extraSearch, page, types},(response)=>{
            setData(response.data||[])
            setCount(response.count||0)
            setError()
            setDisabled(false)
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            setDisabled(false)
        })
    }

    const propsToChildren = {
        handelRefresh,
        setObjectToEdit,
    }

    const classes = useStyles();
    return (
        <div className="fullWidthContainer" >
            <MarketingPostsDialog {...propsToChildren} objectToEdit={objectToEdit} searchParams={{search, fieldsToSearch, extraSearch, page}}/>
            <SearchExtra 
                extraSearch={extraSearch} 
                disabled={disabled} 
                showExtraSearch={showExtraSearch} 
                setShowExtraSearch={setShowExtraSearch} 
                search={handleExtraSearch}
            />

            <Paper style={{margin:'0 4px', padding: 8, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}} elevation={1}>
                <div>
                    {(error)&&<Typography color="error">{error}</Typography>}
                    {(cells.length>0)&&<Search handleSearch={handleSearch} disabled={disabled} cells={cells}/>}
                </div>
                <div>
                    <ToggleButtonGroup value={types} onChange={handleTypesChange} size='small'>
                        <ToggleButton value="Review" classes={{ selected: classes.selected }}>
                            Reviews
                        </ToggleButton>
                        <ToggleButton value="Video" classes={{ selected: classes.selected }}>
                            Videos
                        </ToggleButton>
                        <ToggleButton value="Professional" classes={{ selected: classes.selected }}>
                            Professionals
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
                <IconButton disabled={props.disabled} aria-label="Filter" color='primary' style={{padding: 10}} onClick={handleShowFilter}>
                    <FilterListIcon fontSize='small' />
                </IconButton>
            </Paper>
            <Paper style={{margin:'2px 4px', position:'relative', overflow:'auto', height:'calc(100% - 112px)'}} elevation={1}>
                <JSData cells={cells} data={data} handleNewRow={handleNew} handleEditRow={handleEditRow}/>
            </Paper>
            <Paper style={{margin:'0 4px'}} elevation={1}>
                <JSTablePagination
                    count={count}
                    rowsPerPage={100}
                    page={page}
                    onChangePage={handleChangePage}
                    disabled={disabled}
                >
                    <IconButton color='primary' href='/marketing-calendar' target='_blank'>
                        <DateRangeIcon fontSize='small' />
                    </IconButton>
                </JSTablePagination>
            </Paper>
        </div>
    )
}
import React, { useState, useEffect } from 'react';
import AdminJHApi from "../../utils/AdminJHApi"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@material-ui/core';
import { Fields } from '../Fields';
import { FIELD_TYPES, showDateTimeFromString } from '../../utils/utils';
import { Autocomplete } from '@material-ui/lab';

export default function MarketingEvent(props) {
    const objectToEdit = props.objectToEdit
    
    const [error, setError] = useState();
    const [editId, setEditId] = useState();
    const [savedObjectData, setSavedObjectData] = useState();
    const [newData, setNewData] = useState({});
    const [mode, setMode] = useState();
    const [disabled, setDisabled] = useState(false);

    const [searchPost, setSearchPost] = useState('');
    const [openAutoComplete, setOpenAutoComplete] = useState(false);
    const [options, setOptions] = useState([]);
    const [loadPosts, setLoadPosts] = useState(false);

    const post = (savedObjectData && savedObjectData.posts && savedObjectData.posts[0]) ? savedObjectData.posts[0] : {}

    useEffect(() => {
        if (objectToEdit){
            if (objectToEdit._id){
                setEditId(objectToEdit._id)
                setMode('VIEW')
                setSavedObjectData({...objectToEdit})
            } else {
                setEditId(-1)
                setMode('EDIT')
                setNewData({...objectToEdit})
                setSavedObjectData({...objectToEdit})
                setError()
            }
        } else {
            setEditId()
        }
    }, [objectToEdit]);

    const handleClose = () => {
        props.setObjectToEdit()
    }

    const handleEdit = () => {
        setError()
        setMode('EDIT')
        if (post){
            setNewData({postName:post.name})
            setOptions([post])
        } else {
            setNewData({})
        }
    }

    const handleCancel = () => {
        if (editId === -1){
            handleClose()
        } else {
            setMode('VIEW')
            setError()
            setNewData()
        }
    }

    const handleSave = e => {
        e.preventDefault();

        setDisabled(true)

        AdminJHApi.createEditMEvent({newData, editId},(response)=>{
            setSavedObjectData({...response})
            setMode('VIEW')
            setDisabled(false)
            props.handleRefresh()
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            setDisabled(false)
        })
    }

    const handleChangeField = e => {
        if (e.target.name === 'dateTime'){
            setNewData({
                ...newData,
                [e.target.name]: e.target.value,
                date: e.target.value.substring(0,10),
                timeHours: e.target.value.substring(11,13),
                timeMinutes: e.target.value.substring(14,16)
            })
        } else {
            setNewData({...newData,[e.target.name]: e.target.value})
        }
    };

    const getValue = (name, defaultValue) => {
        if (newData[name] !== undefined){
            return newData[name]
        } else if (savedObjectData[name] !== undefined){
            return savedObjectData[name]
        } else {
            return defaultValue
        }
    }

    const handleSelectPostOption = (event, newValue) => {
        if (newValue && newValue._id){
            setNewData({...newData,postId: newValue._id, postName:newValue.name})
            setOptions([newValue])
        } else {
            setNewData({...newData,postId: '', postName:''})
        }

        setSearchPost('')
    }

    const handleChangePostInput  = e => {
        if(!e || !e.target || typeof e.target.value !== 'string'){
            return
        }

        setNewData({...newData,postName: e.target.value})

        const firstThreeLetters = e.target.value.substring(0, 2)

        if(!loadPosts && e.target.value.length > 1 && searchPost !== firstThreeLetters){
            setSearchPost(firstThreeLetters)
            setLoadPosts(true)

            AdminJHApi.findMPosts({search:firstThreeLetters},(response)=>{
                setOptions(response);
                setLoadPosts(false)
                //setOptions(Object.keys(countries).map((key) => countries[key].item[0]));
            }, (error) => {
                setLoadPosts(false)
            })
        } else if(e.target.value.length === 0){
            setOptions([]);
            setSearchPost('')
        }
    }

    const locations = [
        {value: "BigPage / Horizontal teaser", label:"BigPage / Horizontal teaser"},
        {value: "Groups", label:"Groups"},
        {value: "JobHunt", label:"JobHunt"},   
    ]

    const fields = [
        { name: 'dateTime', label: 'Date To Post', defaultValue: '', type:FIELD_TYPES.DATE_TIME, sm:12}, 
        { name: 'location', label: 'Location', defaultValue: '', options:locations, type:FIELD_TYPES.SELECT, sm:12}, 
    ]

    /*
    dateCreated: { type: Date, default: Date.now },
        date : { type: Date, index: true },
        timeHours : Number,
        timeMinutes : Number,
        location : String,
        postId : mongoose.Schema.Types.ObjectId,
        status : String,
        statusHistory : Array
    */

    return (
        <Dialog onClose={handleClose} open={(editId!==undefined)} fullWidth maxWidth="sm">
            {(mode === 'VIEW')&&<>
            <DialogTitle>{post.name} - <strong>{post.type}</strong></DialogTitle>
                <DialogContent dividers>
                    <Typography>{showDateTimeFromString(savedObjectData.dateTime, true)}</Typography>
                    <Typography>{savedObjectData.location}</Typography>
                    <Typography><a target='_blank' rel="noopener noreferrer" href={post.contentLink}>Content Link</a></Typography>
                </DialogContent>
                <DialogActions>
                    <Button disabled={disabled} onClick={handleEdit} color="primary" size="small" aria-label="Edit">
                        Edit
                    </Button>
                    <Button disabled={disabled} onClick={handleClose} color="primary" size="small" aria-label="Close">
                        Close
                    </Button>
                </DialogActions>
            </>}
            {(mode === 'EDIT')&&<form onSubmit={handleSave}>
                <DialogContent dividers>
                    {(error)&&<Typography color="error">{error}</Typography>}
                    <Fields fields={fields} getValue={getValue} handleChange={handleChangeField}/>
                    <Grid container spacing={3}>
                        <Grid item sm={12} xs={12}>
                            <Autocomplete
                                onChange={handleSelectPostOption}
                                inputValue={getValue('postName', '')}
                                onInputChange={handleChangePostInput}
                                
                                open={openAutoComplete}
                                onOpen={() => {setOpenAutoComplete(true);}}
                                onClose={() => {setOpenAutoComplete(false);}}
                                getOptionSelected={(option, value) => option._id === value._id}
                                getOptionLabel={(option) => option.name}
                                options={options}
                                loading={loadPosts}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name='postId' label='Post' margin="none" fullWidth
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button disabled={disabled} onClick={handleCancel} color="primary" size="small" aria-label="Cancel">
                        Cancel
                    </Button>
                    <Button
                        disabled={disabled}
                        type="submit"
                        variant="outlined"
                        color="primary"
                        size="small"
                        aria-label='Save'
                    >
                        Save
                    </Button>
                </DialogActions>
            </form>}
        </Dialog>
    )
}
import React, { useState, useEffect } from 'react';
import AdminJHApi from "../../utils/AdminJHApi"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { Fields } from '../Fields';
import { FIELD_TYPES, showDateTimeFromString } from '../../utils/utils';

export default function MarketingPostsDialog(props) {
    const objectToEdit = props.objectToEdit
    
    const [error, setError] = useState();
    const [editId, setEditId] = useState();
    const [savedObjectData, setSavedObjectData] = useState();
    const [newData, setNewData] = useState({});
    const [mode, setMode] = useState();
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        if (objectToEdit){
            if (objectToEdit._id){
                setEditId(objectToEdit._id)
                setMode('VIEW')
                setSavedObjectData({...objectToEdit})
            } else {
                setEditId(-1)
                setMode('EDIT')
                setNewData({})
                setError()
            }
        } else {
            setEditId()
        }
    }, [objectToEdit]);

    const handleClose = () => {
        props.setObjectToEdit()
    }

    const handleEdit = () => {
        setNewData({})
        setError()
        setMode('EDIT')
    }

    const handleCancel = () => {
        if (editId === -1){
            handleClose()
        } else {
            setMode('VIEW')
            setError()
            setNewData()
        }
    }

    const handleSave = e => {
        e.preventDefault();

        setDisabled(true)

        AdminJHApi.createEditMPost({newData, editId},(response)=>{
            setSavedObjectData({...response})
            setMode('VIEW')
            setDisabled(false)

            props.handelRefresh()
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            setDisabled(false)
        })
    }

    const handleChangeField = e => {
        setNewData({...newData,[e.target.name]: e.target.value})
    };

    const getValue = (name, defaultValue) => {
        if (newData[name] !== undefined){
            return newData[name]
        } else if (savedObjectData[name] !== undefined){
            return savedObjectData[name]
        } else {
            return defaultValue
        }
    }

    const types = [
        {value: "Review", label:"Review"},
        {value: "Video", label:"Video"},
        {value: "Professional", label:"Professional"},
    ]

    const statuses = [
        {value: "open", label:"open"},
        {value: "closed", label:"closed"},
    ]

    const fields = [
        { name: 'name', label: 'Name', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12}, 
        { name: 'type', label: 'Type', defaultValue: '', options:types, type:FIELD_TYPES.SELECT, sm:12}, 
        { name: 'status', label: 'Status', defaultValue: '', options:statuses, type:FIELD_TYPES.SELECT, sm:12}, 
        { name: 'contentLink', label: 'ContentLink', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12}, 
        /*
        dateCreated: { type: Date, default: Date.now },
        name: String,
        status: String,
        uniqueId: String,
        type: String,
        seniority: String,
        content: String,
        attachment: Array 
        */
    ]

    return (
        <Dialog onClose={handleClose} open={(editId!==undefined)} fullWidth maxWidth="sm">
            {(mode === 'VIEW')&&<>
                <DialogTitle>{savedObjectData.name} - <strong>{savedObjectData.type}</strong></DialogTitle>
                <DialogContent dividers>
                    <Typography><a target='_blank' rel="noopener noreferrer" href={savedObjectData.contentLink}>Content Link</a></Typography>
                    {savedObjectData&&savedObjectData.publishes&&<div style={{marginTop:8}}>
                        {savedObjectData.publishes.map((publish) => {
                            return (
                                <Typography key={publish._id}>{showDateTimeFromString(publish.dateTime, true)} {publish.location}</Typography>
                            )
                        })}
                    </div>}
                </DialogContent>
                <DialogActions>
                    <Button disabled={disabled} onClick={handleEdit} color="primary" size="small" aria-label="Edit">
                        Edit
                    </Button>
                    <Button disabled={disabled} onClick={handleClose} color="primary" size="small" aria-label="Close">
                        Close
                    </Button>
                </DialogActions>
            </>}
            {(mode === 'EDIT')&&<form onSubmit={handleSave}>
                <DialogContent dividers>
                    {(error)&&<Typography color="error">{error}</Typography>}
                    <Fields fields={fields} getValue={getValue} handleChange={handleChangeField}/>
                </DialogContent>
                <DialogActions>
                    <Button disabled={disabled} onClick={handleCancel} color="primary" size="small" aria-label="Cancel">
                        Cancel
                    </Button>
                    <Button
                        disabled={disabled}
                        type="submit"
                        variant="outlined"
                        color="primary"
                        size="small"
                        aria-label='Save'
                    >
                        Save
                    </Button>
                </DialogActions>
            </form>}
        </Dialog>
    )
}
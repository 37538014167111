import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { PersonalJourneyClasses } from '../../../styles/PersonalJourneyClasses';
import CVManager from './CVManager';
import CoverLetterManager from './CoverLetterManager';

export default function CVCover(props) {
    const classes = PersonalJourneyClasses();

    return (
        <div>
            <Paper className={classes.prePublishSection} elevation={1} style={{marginTop:0}}>
                <Typography> 
                    <a href="https://docs.google.com/spreadsheets/u/1/d/1iTkuEuUcDzJuOA32aJLvaAQbICZGgcsmnGEk4F8nqgA/edit#gid=0" target="_blank" rel="noopener noreferrer">קורות חיים ומכתב מקדים - דוגמאות</a>
                </Typography>
            </Paper>

            <Paper className={classes.prePublishSection} elevation={1} style={{marginTop:0}}>
                <CVManager {...props}/>
            </Paper>

            <Paper className={classes.prePublishSection} elevation={1} style={{marginTop:0}}>
                <CoverLetterManager {...props}/>
            </Paper>

            <Paper className={classes.prePublishSection} elevation={1} style={{marginTop:0}}>
                <Typography dir="auto"> 
                    הסבר על גוגל דוקס:<br/><br/>
                    <a href="https://www.youtube.com/watch?v=-mE3-LhuAms" target='_blank' rel="noopener noreferrer">כיצד מעלים קובץ לדוקס</a><br/><br/>
                    <a href="https://www.youtube.com/watch?v=qRcu2_OEUqI" target='_blank' rel="noopener noreferrer">כיצד עושים שיתוף לקובץ</a>
                </Typography>
            </Paper>
        </div>
    )
}
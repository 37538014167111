import React, { Component, Suspense, lazy } from 'react'
import { /*BrowserRouter as*/ Router, Route, Switch } from 'react-router-dom'
import HistogramC from '../containers/HistogramC'
import Header from './Header'
import Head from '../containers/Head'
import SocialAuthRedirect from './Login/SocialAuthRedirect'
import MainDialog from '../containers/MainDialog';
import Users from '../containers/Users'
import JHReviewsPC from '../containers/JH/JHReviewsPC'
import PersonalJourneyPC from '../containers/JH/PersonalJourneyPC'
import JHSettings from '../containers/JH/JHSettings'
import JHPaymentLinkPC from '../containers/JH/JHPaymentLinkPC'
import GlobalContainer from '../containers/GlobalContainer'
import Interviews from './Interviews/Interviews'
import SynamediaClientsPC from '../containers/SynamediaClientsPC'
import Events from './events/Events'
import JHPublishesPC from '../containers/JH/JHPublishesPC'
import JHPC from '../containers/JH/JHPC'
import DashboardPC from '../containers/JH/DashboardPC'
import BflClientsPC from '../containers/BflClientsPC'
import JobsFound from './JH/JobsFound'
import SynamediaClientsStatus from './JH/SynamediaClientsStatus'
import Leads from './JH/Leads'
import InterviewsReport from './JH/InterviewsReport'
import Kpis from './JH/Kpis'
import DashboardJH from './JH/DashboardJH'
import MarketingPosts from './JH/MarketingPosts'
import MarketingCalendar from './JH/MarketingCalendar'
//import Company from '../containers/Company'

const Templates = lazy(() => import('../containers/Templates'));
const HtmlTemplates = lazy(() => import('../containers/HtmlTemplates'));
const Payments = lazy(() => import('../containers/Payments'));
const Privacy = lazy(() => import('./Privacy'));
const Terms = lazy(() => import('./Terms'));
const Settings = lazy(() => import('../containers/Settings'));
const Notifications = lazy(() => import('../containers/Notifications'));
const SendPushContainer = lazy(() => import('../containers/SendPushContainer'));
const JSSetings = lazy(() => import('../containers/JSSetings'));
const Statistics = lazy(() => import('./JH/Statistics'));
const Questionnaire = lazy(() => import('./JH/Questionnaire'));
const Dashboard = lazy(() => import('./JH/Dashboard'));
//const ClientsWeekly = lazy(() => import('./JH/personalJourney/ClientsWeekly'));

export default class App extends Component {
    render() {
        const history = this.props.history
        const isJobHunt = this.props.isJobHunt

        return (
            <React.Fragment>
                <Head/>
                <MainDialog/>
                <Header isJobHunt={isJobHunt }/>
                <main>
                    <Router history={history}>
                    <Suspense fallback={<div>Loading...</div>}>
                        <Switch>
                            <Route path="/socialauthredirect/" component={SocialAuthRedirect} />
                            <Route path="/signupConfrimation/" render={(props) => <SocialAuthRedirect {...props} signupConfrimation />}/>
                            <Route path="/profile" render={(props) => <HistogramC {...props} histogramType={'profile'} />}/>

                            <Route path="/reports" render={(props) => <HistogramC {...props} histogramType={'reports'} />}/>
                            <Route path="/templates/:template" component={Templates}/> 
                            <Route path="/templates" component={Templates}/> 
                            <Route path="/htmltemplates/:template" component={HtmlTemplates}/> 
                            <Route path="/htmltemplates" component={HtmlTemplates}/>
                            <Route path="/users/:userId" component={Users}/>
                            <Route path="/users" component={Users}/>
                            
                            {/*<Route path="/companies/:company" component={Company}/>*/}

                            <Route path="/payments" component={Payments}/>

                            <Route path="/privacy" component={Privacy}/> 
                            <Route path="/terms" component={Terms}/> 

                            <Route path="/settings" component={Settings}/> 
                            <Route path="/notifications" component={Notifications}/> 
                            <Route path="/saveditems" render={(props) => <HistogramC {...props} histogramType={'saveditems'} />}/> 
                            <Route path="/sendpushnotifications" component={SendPushContainer}/> 
                            <Route path="/jssettings" component={JSSetings}/> 

                            <Route path="/jobhunt-reviews" component={JHReviewsPC}/> 

                            <Route path="/jobhunt-statistics" render={(props) => <JHPC {...props}><Statistics/></JHPC>}/>
                            <Route path="/jobs-found" render={(props) => <JHPC {...props}><JobsFound/></JHPC>}/>
                            {/*<Route path="/clients-weekly" render={(props) => <JHPC {...props}><ClientsWeekly/></JHPC>}/>*/}

                            <Route path="/jobhunt-settings" component={JHSettings}/> 
                            <Route path="/jobhunt-payment" component={JHPaymentLinkPC}/>
                            {/*<Route path="/galina-emails" render={(props) => <JHPC {...props}><GalinaEmails/></JHPC>}/> */}
                            <Route path="/jobhunt-publishes" component={JHPublishesPC}/>                            

                            {/*<Route path="/faq" component={Faq}/> */}

                            <Route path="/myJobs" render={(props) => <HistogramC {...props} histogramType={'myJobs'} />}/>
                            <Route path="/activity/:activityView" render={(props) => <HistogramC {...props} histogramType={'activity'} />}/>
                            <Route path="/activity" render={(props) => <HistogramC {...props} histogramType={'activity'} />}/>
                            <Route path="/newPassword" render={(props) => <HistogramC {...props} histogramType={'main'} newPassword />}/>

                            <Route path="/jobs/:job" render={(props) => <HistogramC {...props} histogramType={'jobs'} />}/>
                            <Route path="/jobs" render={(props) => <HistogramC {...props} histogramType={'jobs'} />}/>
                            <Route path="/resumes/:job" render={(props) => <HistogramC {...props} histogramType={'resumes'} />}/>
                            <Route path="/resumes" render={(props) => <HistogramC {...props} histogramType={'resumes'} />}/>
                            <Route path="/interviews/:param" render={(props) => <GlobalContainer {...props}><Interviews/></GlobalContainer>}/> 
                            <Route path="/interviews" render={(props) => <GlobalContainer {...props}><Interviews/></GlobalContainer>}/> 
                            <Route path="/events/:eventUrl" render={(props) => <GlobalContainer {...props}><Events/></GlobalContainer>}/> 

                            <Route path="/synamediaClients" component={SynamediaClientsPC} /> 
                            <Route path="/bflClients" component={BflClientsPC} /> 

                            {/*<Route path="/jobhunt-clients-status" render={(props) => <JHPC {...props}><SynamediaClientsStatus/></JHPC>}/>*/}
                            <Route path="/jobhunt-clients-status" component={SynamediaClientsStatus}/> 
                            <Route path="/leads" render={(props) => <JHPC {...props}><Leads/></JHPC>}/>
                            <Route path="/questionnaires" render={(props) => <JHPC {...props}><Questionnaire/></JHPC>}/>
                            <Route path="/interviews-report" render={(props) => <JHPC {...props}><InterviewsReport/></JHPC>}/>

                            <Route path="/dashboard" render={(props) => <JHPC {...props}><Dashboard/></JHPC>}/>

                            {/*<Route path="/interviews/:job" render={(props) => <HistogramC {...props} histogramType={'interviews'} />}/>
                            <Route path="/interviews" render={(props) => <HistogramC {...props} histogramType={'interviews'} />}/>*/}
                            {/*<Route path="/companies/:job" render={(props) => <HistogramC {...props} histogramType={'companies'} />}/>
                            <Route path="/companies" render={(props) => <HistogramC {...props} histogramType={'companies'} />}/>
                            <Route path="/reviews/:job" render={(props) => <HistogramC {...props} histogramType={'companies'} cardContent={'Reviews'}/>}/>
                            <Route path="/interviews/:job" render={(props) => <HistogramC {...props} histogramType={'companies'} cardContent={'Interviews'}/>}/>*/}

                            <Route path="/jobhunt-users/:job" render={(props) => <HistogramC {...props} histogramType={'jobhuntUsers'} />}/>
                            <Route path="/jobhunt-users" render={(props) => <HistogramC {...props} histogramType={'jobhuntUsers'} />}/>

                            <Route path="/kpis" render={(props) => <JHPC {...props}><Kpis/></JHPC>}/>
                            <Route path="/Journey-table-view" render={(props) => <DashboardPC {...props}><DashboardJH/></DashboardPC>}/>
                            <Route path="/personal-journey" component={PersonalJourneyPC}/> 

                            <Route path="/marketing-posts" render={(props) => <JHPC {...props}><MarketingPosts/></JHPC>}/>
                            <Route path="/marketing-calendar" render={(props) => <JHPC {...props}><MarketingCalendar/></JHPC>}/>

                            {!isJobHunt&&<Route path="/" render={(props) => <HistogramC {...props} histogramType={'main'} />}/>}
                            {isJobHunt&&<Route path="/" render={(props) => <DashboardPC {...props}><Kpis/></DashboardPC>}/>}
                            
                            {/*<Route path="/example" component={AsyncApp} />*/}
                        </Switch>
                    </Suspense>
                    </Router>
                </main>
            </React.Fragment>
        )
    }
}
import React, { useState, useEffect } from 'react';
import { IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AdminJHApi from "../../utils/AdminJHApi"
import clsx from  'clsx';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';
import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';
import MarketingEvent from './MarketingEvent';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListIcon from '@material-ui/icons/List';

const useStyles = makeStyles((theme) => ({
    cell: {
        border: "1px solid rgba(224, 224, 224, 1)",
        padding: 4
    },
    isToday: {
        fontWeight: 'bold',
        color: theme.palette.primary.main
    },
    cellOnList: {
        transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    },
    userRow:{
        transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    },
    problem: {
        background: red[300],
        /*'& $cell': {
            color:'white'
        }*/
    },
    published:{
        background: green[200],
    },
    publishing:{
        background: yellow[200],
    },
    publishfailed:{
        background: red[300],
    },
    selected:{
        background: blue[100],
    },
    selectedCanDelete:{
        background: purple[200],
        '&:hover': {
            background: 'white',
        }
    },
    hidden:{
        backgroundColor: grey[400]
    },
    canSelect:{
        cursor: 'pointer',
        /*'&:hover': {
            background: blue[100],
        }*/
    },
    canFill:{
        cursor: 'pointer',
        '&:hover': {
            background: purple[200],
        }
    }
}));

export default function MarketingCalendar(props) {
    const PUBLISH_TIMES = [8,9,10,11,12,13,14,15,16,17]

    const [error, setError] = useState();
    const [dates, setDates] = useState({});
    const [events, setEvents] = useState([]);
    const [disabled, setDisabled] = useState(false);

    const [objectToEdit, setObjectToEdit] = useState();

    useEffect(() => {
        AdminJHApi.getMCalendar({},(response)=>{
            setDates({startDate:response.startDate, endDate:response.endDate, dates: response.dates})
            setEvents(response.events)
            console.log(response)
            setError()
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
        })
    }, []);

    const handeleEditEvent = object => {
        setObjectToEdit({...object})
    }

    const handlePrev = () => {
        AdminJHApi.getMCalendar({startDate:dates.startDate},(response)=>{
            setDates({startDate:response.startDate, endDate:response.endDate, dates: response.dates})
            setEvents(response.events)
            setError()
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
        })
    }

    const handleNext = () => {
        setDisabled(true)

        AdminJHApi.getMCalendar({endDate:dates.endDate},(response)=>{
            setDates({startDate:response.startDate, endDate:response.endDate, dates: response.dates})
            setEvents(response.events)
            setError()
            setDisabled(false)
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            setDisabled(false)
        })
    }

    const handleRefresh = () => {
        setDisabled(true)

        AdminJHApi.getMCalendar({startDate:dates.startDate, endDate:dates.endDate},(response)=>{
            setDates({startDate:response.startDate, endDate:response.endDate, dates: response.dates})
            setEvents(response.events)
            setError()
            setDisabled(false)
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            setDisabled(false)
        })
    }

    const propsToChildren = {
        handleRefresh,
        setObjectToEdit
    }

    const today = new Date()
    const classes = useStyles();
    return (
        <div className="fullWidthContainer" >
            <MarketingEvent {...propsToChildren} objectToEdit={objectToEdit}/>
            {dates.startDate&&<>
            <Paper style={{margin:'0 4px', padding: 8, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}} elevation={1}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    {(error)&&<Typography color="error">{error}</Typography>}
                    <IconButton disabled={disabled} color='primary' style={{padding: 10}} onClick={handlePrev}>
                        <ChevronLeftIcon fontSize='small' />
                    </IconButton> 
                    {new Date(dates.startDate).getDate()}.{new Date(dates.startDate).getMonth()+1} - {new Date(dates.endDate).getDate()}.{new Date(dates.endDate).getMonth()+1} 
                    <IconButton disabled={disabled} color='primary' style={{padding: 10}} onClick={handleNext}>
                        <ChevronRightIcon fontSize='small' />
                    </IconButton> 
                </div>
            </Paper>
            <Paper style={{margin:'2px 4px', position:'relative', overflow:'auto', height:'calc(100% - 112px)'}} elevation={1}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.cell}></TableCell>
                            {dates.dates.map((date) => {
                                const dateObj = new Date(date)
                                const isToday = (dateObj.getDate() === today.getDate() && dateObj.getMonth() === today.getMonth() && dateObj.getFullYear() === today.getFullYear())

                                return (
                                    <TableCell key={date} className={clsx(classes.cell,isToday&&classes.isToday)}>{dateObj.getDate()}.{dateObj.getMonth()+1}</TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {PUBLISH_TIMES.map((publishTime) => {
                        return (
                            <TableRow key={publishTime}>
                                <TableCell component="th" scope="row" className={classes.cell}>{publishTime}:00</TableCell>
                                {dates.dates.map((date) => {
                                    const eventObj = {
                                        dateTime: `${new Date(date).toISOString().split("T")[0]}T${String(publishTime).padStart(2, '0')}:${String(0).padStart(2, '0')}`,
                                        date: new Date(date).toISOString().split("T")[0],
                                        timeHours: publishTime,
                                        timeMinutes: 0
                                    }

                                    const eventsInCell = events.filter(event => (event.date === eventObj.date && event.timeHours === eventObj.timeHours))
                                    
                                    return (
                                        <TableCell key={date} className={clsx(classes.cell, classes.cellOnList, classes.canSelect)} onClick={()=>{if(eventsInCell.length===0){handeleEditEvent(eventObj);}}}>
                                            {eventsInCell.map((eventInCell) => (
                                                <div key={eventInCell._id} onClick={()=>{handeleEditEvent(eventInCell)}} style={{padding: '8px 4px', border: '1px solid grey', borderRadius: 5}}>
                                                    {eventInCell.posts[0].name}
                                                </div>
                                            ))}
                                            {(eventsInCell.length>0)&&<div style={{color:'blue', padding: '8px 4px', border: '1px solid grey', borderRadius: 5}} onClick={()=>{handeleEditEvent(eventObj)}}>
                                                add
                                            </div>}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        )
                    })}
                    </TableBody>
                </Table>
            </Paper>
            <Paper style={{margin:'0 4px'}} elevation={1}>
                <div>
                    <IconButton color='primary' href='/marketing-posts' target='_blank'>
                        <ListIcon fontSize='small' />
                    </IconButton>
                </div>
            </Paper>
            </>}
        </div>
    )
}